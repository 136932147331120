<template>
  <v-chart :option="options" :auto-resize="true" @rendered="rendered = true" />
</template>

<script>
export default {
  props: {
    getChartOptions: Function,
    getData: Function,
    value: Object,
    refreshInterval: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      data: null,
      refreshTimer: null
    }
  },
  computed: {
    options: function () {
      return this.getChartOptions({ data: this.data, isUpdate: this.rendered })
    }
  },
  mounted () {
    this.updateData()
    if (this.refreshInterval > 0) {
      this.refreshTimer = setInterval(() => this.updateData(), this.refreshInterval)
    }
  },
  destroyed () {
    this.refreshInterval && clearInterval(this.refreshInterval)
  },
  watch: {
    value (val) {
      this.updateData(val)
    }
  },
  methods: {
    async updateData (val) {
      this.data = val || this.value || await this.getData()
    }
  }
}
</script>
