<template>
  <div>
    <h2>Booking Availability by Provider</h2>
    <div class="filters">
      <a-range-picker
        :ranges="filterDateRangePresets"
        format="YYYY/MM/DD"
        :defaultValue="[filters.dateStart, filters.dateEnd]"
        @change="handleDateRangeChange"
      />
    </div>
    <div class="map-chart">
      <a-spin :spinning="loading" :delay="1000">
      <chart
        v-if="chartData"
        ref="chart"
        style="width: 100%;"
        :style="{ height }"
        :getChartOptions="getChartOptions"
        :getData="() => chartData"
      />
      </a-spin>
    </div>
  </div>
</template>

<script>
import { queryBookingData } from '@/api/appointment'
import chart from '@/components/chart'
import moment from 'moment'
import { time, use } from 'echarts/core'
import { MarkPointComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
use([BarChart, MarkPointComponent])

const getTimeGroupedAppointments = (appointments) => {
  // Free排在最后
  return appointments.sort((a, b) => b.status === 'Free' ? -1 : 0).reduce((m, a) => {
    let time = moment.utc(a.start_date)
    if (time.minute() !== 0 && time.minute() !== 30) {
      time.minute(Math.floor(time.minute() / 30) * 30)
    }
    time = time.valueOf()
    if (!m[time]) {
      m[time] = [a]
      return m
    }
    const pm = m[time].filter(t => t.practitioner_id === a.practitioner_id)
    if (!pm.length) {
      // 没有practitioner的appointment,直接加入
      m[time].push(a)
      return m
    }

    if (a.status === 'Free') {
      // Free遇到Cancel，踢掉Cancel保留Free
      if (pm.find(t => t.status === 'Canceled')) {
        m[time] = [...m[time].filter(t => t.practitioner_id !== a.practitioner_id), a]
      }
    } else if (a.status === 'Canceled') {
      // （这里有其他项存在）Cancel的直接忽略
    } else {
      // 如果是其他(Missed/Confirmed)状态，删除同一个时间段内Canceled
      if (pm.find(t => t.status === 'Canceled')) {
        m[time] = [...m[time].filter(t => t.practitioner_id !== a.practitioner_id), a]
      } else {
        m[time].push(a)
      }
    }
    return m
  }, {})
}

export default {
  components: { chart },
  data () {
    return {
      rawData: null,
      loading: false,
      filterDateRangePresets: {
        'Today': [moment().startOf('day'), moment().endOf('day')],
        '3 Days': [moment().startOf('day'), moment().endOf('day').add(2, 'days')],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
      },
      filters: {
        dateStart: moment().startOf('day'),
        dateEnd: moment().endOf('day').add(2, 'days'),
      }
    }
  },
  computed: {
    height () { return this.chartData.length * 40 + 100 + 'px' },
    chartData () { 
      if (!this.rawData) return null
      return this.rawData.map(({free, initial, followup, ...others}) => ({
        ...others,
        free: Math.floor(free / 60 * 100) / 100, 
        initial: Math.floor(initial / 60 * 100) / 100, 
        followup: Math.floor(followup / 60 * 100) / 100, 
        availability: Math.floor((free / (free + initial + followup)) * 10000) / 100
      }))
    }
  },
  mounted() {
    this.updateData()
  },
  methods: {
    async getData (filters) {
      const { appointments, practitioners } = await queryBookingData(filters || this.filters)
      practitioners.push({
        id: 'null',
        name: '(Unkown)'
      })
      this.categoryCount = practitioners.length
      const practitionerMap = practitioners.reduce((m, l) => {
        m[l.id] = {
          free: 0,
          initial: 0,
          followup: 0
        }
        return m
      }, {})
      const timeGroupedAppointments = getTimeGroupedAppointments(appointments)
      Object.values(timeGroupedAppointments).flat().forEach(a => {
        if (a.status === 'Canceled') {
          return
        }
        if (a.status === 'Free') {
          practitionerMap[a.practitioner_id].free += a.duration
          return
        }
        if (!a.service_name) {
          console.warn(a)
          return
        }
        if (a.service_name.toLowerCase().indexOf('initial') > -1) {
          practitionerMap[a.practitioner_id].initial += a.duration
        } else if (a.service_name.toLowerCase().indexOf('follow-up') > -1) {
          practitionerMap[a.practitioner_id].followup += a.duration
        }
      })
      practitioners.forEach(l => {
        const data = practitionerMap[l.id]
        if (!data) return
        let { free, initial, followup } = data
        Object.assign(l, { free, initial, followup })
      })
      return practitioners
    },
    async updateData () {
      this.loading = true
      let dateStart = moment(this.filters.dateStart)
      let dateEnd = moment(this.filters.dateEnd)
      // console.log(dateStart, dateEnd, dateEnd.diff(dateStart, 'days'))
      const end = dateEnd
      const mergedData = []
      const mergeItem = (item, newItem) => {
        Object.entries(newItem).forEach(([k, v]) => {
          item[k] = typeof item[k] === 'number' ? item[k] + v : v
        })
      }
      const merge = (data, newData) => {
        newData.forEach(newItem => {
          let item = data.find(t => t.id === newItem.id)
          item ? mergeItem(item, newItem) : data.push(newItem)
        })
      }
      while(dateStart.isSameOrBefore(end)) {
        dateEnd = dateStart.clone().add(30, 'days')
        dateEnd = dateEnd.isAfter(end) ? end : dateEnd
        merge(mergedData, await this.getData({dateStart, dateEnd}))
        dateStart = dateEnd.clone().add(1, 'day')
      }
      this.rawData = null
      await this.$nextTick()
      this.rawData = mergedData
      console.log(this.rawData, this.chartData)
      // this.$refs.chart.updateData()
      this.loading = false
    },
    getChartOptions ({ data }) {
      data = (data || []).filter(t => t.free || t.initial || t.followup).reverse()
      const categoryData = data.map(t => t.name)
      return {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['Available', 'Initial Appointment', 'Follow-up Appointment']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: [{
          type: 'category',
          data: categoryData,
          axisLabel: {
            textStyle: {
              fontSize: 18,
            },
          },
        },
        {
          type: 'category',
          axisLabel: {
            align: 'left',
            // margin: 10,
            show: true,
            formatter: (v) => v + '% Availability',
            textStyle: {
              fontSize: 18,
            },
          },
          splitLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          data: data.map(t => t.availability),
        }],
        series: [
          {
            name: 'Available',
            type: 'bar',
            label: {
              show: true,
              formatter: ({value}) => value + ' hrs'
            },
            emphasis: {
              focus: 'series'
            },
            data: data.map(t => t.free)
          },
          {
            name: 'Initial Appointment',
            type: 'bar',
            stack: 'Booked',
            label: {
              show: true,
              formatter: ({value}) => value + ' hrs'
            },
            emphasis: {
              focus: 'series'
            },
            data: data.map(t => t.initial)
          },
          {
            name: 'Follow-up Appointment',
            type: 'bar',
            stack: 'Booked',
            label: {
              show: true,
              formatter: ({value}) => value + ' hrs'
            },
            emphasis: {
              focus: 'series'
            },
            data: data.map(t => t.followup)
          },
          // {
          //   type: 'line',
          //   markPoint: {
          //     symbol: 'pin',
          //     symbolRotate: -90,
          //     label: {
          //       show: true,
          //       position: 'right',
          //     },
          //     data: data.map(({ availability, free, initial, followup }, yAxis) => ({
          //       value: availability,
          //       xAxis: Math.max(free, initial + followup),
          //       yAxis,
          //     }))
          //   },
          // },
        ]
      }
    },
    handleDateRangeChange ([dateStart, dateEnd]) {
      this.filters.dateStart = dateStart
      this.filters.dateEnd = dateEnd
      this.updateData()
    }
  }
}
</script>

<style lang="scss">
.calendar {
  --fc-small-font-size: 0.7em;

  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    box-shadow: none;
    margin: 1px 1px 1px 0;
    cursor: pointer;
  }
}

.filters {
  > * {
    margin: 0.5em;
  }
}
</style>
